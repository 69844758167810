@import "../../../../../../../style/mixins";

.wrapper {
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  border: var(--uxu-border-default);
  background-color: var(--uxu-color-background-default);

  .btnRemoveDefault {
    position: absolute;
    top: var(--uxu-space-small);
    right: var(--uxu-space-small);
    width: 2rem;
    height: 2rem;
    padding: 0;
    margin: 0;
    border: none;
    color: white;
    cursor: pointer;
    transition: color 0.3s;
    background-color: transparent;

    &:hover {
      color: var(--uxu-color-link-secound);
    }
  }

  &.danger,
  &.warning {
    color: var(--uxu-color-background-default);

    .btnRemoveDefault {
      color: var(--uxu-color-link-secound);

      &:hover {
        color: var(--uxu-color-link-secound-hover);
      }
    }
  }

  &.danger {
    background-color: var(--uxu-color-error-1);
  }

  &.warning {
    background-color: var(--uxu-color-warning-1);
  }

  &.success {
    background-color: var(--uxu-color-success-1);
  }

  @include media('m') {
    width: 60rem;
    border-radius: var(--uxu-radii-default);
  }
}

.content {
  padding: var(--uxu-space-default);

  .text {
    font-size: 1.4rem;
  }
}

.actions {
  width: 100%;
  display: flex;
  padding-top: var(--uxu-space-default);
  justify-content: flex-end;
}
