@import "../theme/theme";


.btn {
  height: 3.2rem;
  max-width: 100%;
  display: flex;
  cursor: pointer;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  gap: var(--uxu-space-small);
  background-color: var(--uxu-color-background-third);
  color: var(--uxu-color-link-secound);
  transition: background-color 0.3s ease, border 0.3s ease, color 0.3s ease;
  border-radius: var(--uxu-radii-default);
  font-size: var(--uxu-font-size-default);
  line-height: var(--uxu-line-height-small);
  border: var(--uxu-border-default);
  padding: var(--uxu-space-small) calc(var(--uxu-space-default) - 0.2rem);

  &:focus {
    outline: none;
  }

  &:hover {
    border: var(--uxu-border-active);
    background-color: var(--uxu-color-background-default);
  }
}

.btn.large {
  padding: calc(1.5 * var(--uxu-space-default)) calc(2 * var(--uxu-space-large));
}

.btn.special {
  border: none;
  position: relative;
  background-color: var(--uxu-color-background-default);
  transition: background-image 0.3s ease;

  span {
    height: calc(100% + 0.3rem);
    position: absolute;
    width: calc(100% + 0.3rem);
    z-index: -2;
    border-radius: var(--uxu-radii-default);
    transition: background-image 0.3s ease;

    &:before {
      content: "";
      top: 0;
      left: 0;
      border: 12px solid transparent;
      background-clip: padding-box;
      filter: blur(36px);
      height: 100%;
      opacity: 0.8;
      position: absolute;
      width: 100%;
      z-index: -1;
      transition: background-image 0.3s ease;
    }

    &:nth-child(1), &:nth-child(1):before {
      background-image: var(--uxu-gradient-blue-tell);
      animation: gradient-animation-1 8s infinite;
    }

    &:nth-child(2), &:nth-child(2):before {
      background-image: var(--uxu-gradient-purple-pink);
      animation: gradient-animation-2 8s infinite;
    }

    &:nth-child(3), &:nth-child(3):before {
      background-image: var(--uxu-gradient-red-amber);
      animation: gradient-animation-3 8s infinite;
    }
  }

  &:hover {
    span, span:before {
      background-image: var(--uxu-gradient-gray-gray);
    }
  }
}

@keyframes gradient-animation-1 {
  0%, 33%, 100% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}

@keyframes gradient-animation-2 {
  33%, 100% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
}

@keyframes gradient-animation-3 {
  66%, 100% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}


.btn.disabled {
  cursor: no-drop;
  border: var(--uxu-border-alpha-default);
  color: var(--uxu-color-primary-accent5);
}

.btn.primary {
  border: var(--uxu-border-active);
  color: var(--uxu-color-text-secound);
  background-color: var(--uxu-color-background-foreground);

  &:hover {
    background-color: transparent;
    color: var(--uxu-color-primary-foreground);
  }
}

.btn.tertiary {
  background-color: transparent;
  border: var(--uxu-border-0);
  color: var(--uxu-color-link-secound);

  &:hover {
    color: var(--uxu-color-link-active);
    background-color: var(--uxu-color-background-secound);
  }
}

.btn.tertiary {
  background-color: transparent;
  border: var(--uxu-border-alpha-0);
  color: var(--uxu-color-primary-accent5);

  &:hover {
    color: var(--uxu-color-primary-foreground);
    background-color: var(--uxu-color-background-200);
  }
}

.btn.error {
  border: var(--uxu-border-error);
  color: var(--uxu-color-error-1);
  background-color: rgba(var(--uxu-color-error-1-rgba), var(--uxu-alpha-min));

  &:hover {
    border: var(--uxu-border-active);
    color: var(--uxu-color-text-active);
    background-color: rgba(var(--uxu-color-background-foreground-rgba), var(--uxu-alpha-min));
  }
}

.btn.danger {
  border: var(--uxu-border-danger);
  color: var(--uxu-color-danger-1);
  background-color: rgba(var(--uxu-color-danger-1-rgba), var(--uxu-alpha-min));

  &:hover {
    border: var(--uxu-border-active);
    color: var(--uxu-color-text-active);
    background-color: rgba(var(--uxu-color-background-foreground-rgba), var(--uxu-alpha-min));
  }
}

.btn.warning {
  border: var(--uxu-border-warning);
  color: var(--uxu-color-warning-1);
  background-color: rgba(var(--uxu-color-warning-1-rgba), var(--uxu-alpha-min));

  &:hover {
    border: var(--uxu-border-active);
    color: var(--uxu-color-text-active);
    background-color: rgba(var(--uxu-color-background-foreground-rgba), var(--uxu-alpha-min));
  }
}

.btn.default {
  border: var(--uxu-border-active);
  color: var(--uxu-color-text-active);
  background-color: rgba(var(--uxu-color-background-foreground-rgba), var(--uxu-alpha-min));

  &:hover {
    border: var(--uxu-border-default);
    color: var(--uxu-color-default-1);
    background-color: rgba(var(--uxu-color-default-1-rgba), var(--uxu-alpha-min));
  }
}

.btn.success {
  border: var(--uxu-border-success);
  color: var(--uxu-color-success-1);
  background-color: rgba(var(--uxu-color-success-1-rgba), var(--uxu-alpha-min));

  &:hover {
    border: var(--uxu-border-active);
    color: var(--uxu-color-text-active);
    background-color: rgba(var(--uxu-color-background-foreground-rgba), var(--uxu-alpha-min));
  }
}


.btn.ghost {
  border: var(--uxu-border-default);
  color: var(--uxu-color-primary-accent5);
  background-color: transparent;

  &:hover {
    border: var(--uxu-border-active);
    color: var(--uxu-color-text-active);
  }
}

.btn.ghost.disabled {
  border: var(--uxu-border-alpha-400);
  color: var(--uxu-color-primary-accent5);

  &:hover {
    border: var(--uxu-border-alpha-400);
    color: var(--uxu-color-primary-accent5);
  }
}

.btn.ghost.primary {
  background-color: transparent;
  border: var(--uxu-border-alpha-900);
  color: var(--uxu-color-primary-foreground);

  &:hover {
    border: var(--uxu-border-alpha-400);
  }
}

.btn.ghost.secondary {
  background-color: transparent;
  border: var(--uxu-border-alpha-400);
  color: var(--uxu-color-primary-accent5);

  &:hover {
    border: var(--uxu-border-alpha-900);
    color: var(--uxu-color-primary-foreground);
    background-color: var(--uxu-color-background-200);
  }
}

.btn.ghost.tertiary {
  background-color: transparent;
  border: var(--uxu-border-alpha-100);
  color: var(--uxu-color-primary-accent5);

  &:hover {
    color: var(--uxu-color-primary-foreground);
    background-color: var(--uxu-color-background-200);
  }
}

.btn.ghost.error {
  background-color: transparent;
  color: var(--uxu-color-error-default);
  border: var(--uxu-border-alpha-error-900);

  &:hover {
    color: var(--uxu-color-red-400);
    border: var(--uxu-border-alpha-error-400);
  }
}

.btn.ghost.warning {
  background-color: transparent;
  color: var(--uxu-color-warning-default);
  border: var(--uxu-border-alpha-warning-900);

  &:hover {
    color: var(--uxu-color-amber-400);
    border: var(--uxu-border-alpha-warning-400);
  }
}

.btn.ghost.success {
  background-color: transparent;
  color: var(--uxu-color-success-default);
  border: var(--uxu-border-alpha-success-900);

  &:hover {
    color: var(--uxu-color-blue-400);
    border: var(--uxu-border-alpha-success-400);
  }
}
