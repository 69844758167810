@import "mixins";

p,
h1,
h2,
h3,
h4,
h5,
h6,
b,
a,
span,
strong,
button {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--uxu-color-text-header);
}

button {
  font-size: var(--uxu-font-size-default);
}

h1 {
  font-size: var(--uxu-font-size-h1-mobile);

  @include media('s') {
    font-size: var(--uxu-font-size-h1);
  }
}

h2 {
  font-size: var(--uxu-font-size-h2-mobile);

  @include media('s') {
    font-size: var(--uxu-font-size-h2);
  }
}

h3 {
  font-size: var(--uxu-font-size-h3-mobile);

  @include media('s') {
    font-size: var(--uxu-font-size-h3);
  }
}

h4 {
  font-size: var(--uxu-font-size-h4-mobile);

  @include media('s') {
    font-size: var(--uxu-font-size-h4);
  }
}

h5 {
  font-size: var(--uxu-font-size-h5-mobile);

  @include media('s') {
    font-size: var(--uxu-font-size-h5);
  }
}

h6 {
  font-size: var(--uxu-font-size-h6-mobile);

  @include media('s') {
    font-size: var(--uxu-font-size-h6);
  }
}


.headerSection {
  width: 100%;
  display: block;
  text-align: center;
  font-weight: normal;
  font-size: var(--uxu-font-size-h6);
  color: var(--uxu-color-text-default);
  margin-bottom: var(--uxu-space-large);

  @include media('s') {
    font-size: var(--uxu-font-size-h4);
  }
}