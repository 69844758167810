*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@import "variebles";

html {
  font-size: 62.5%;
}

@import "body";
@import "reset";
@import "typography";
@import "grid";
@import "button";
@import "link";
@import "mixins";
